import Swiper from 'swiper/bundle';

// dynamic Swiper
var swiperCompare;

function resizeCheck() {
    var winWidth = $(window).width();
    if (winWidth < 768) {
        if (!$("body").hasClass("mobile-v")) {
            swiperCompare = new Swiper('.swiper-compare', {
                slidesPerView: 1,

                threshold: 320,
                spaceBetween: 32,
                navigation: {
                    nextEl: ".swiper-compare .swiper-button-next",
                    prevEl: ".swiper-compare .swiper-button-prev",
                },
            });
            $("body").addClass("mobile-v");
        }
    } else {
        if ($("body").hasClass("mobile-v")) {
            if ($('.swiper-compare.swiper-initialized').length) {
                swiperCompare.destroy();
            }
            $("body").removeClass("mobile-v");
        }
    }

    if (winWidth < 1024) {
        if (!$("body").hasClass("tablet-v")) {
            $('.order-cart').appendTo('.tablet-summary-box');
            $('.order-summary-top').appendTo('.tablet-summary-box');
            $("body").addClass("tablet-v");
        }
    } else {
        if ($("body").hasClass("tablet-v")) {
            $('.order-cart').appendTo('.order-cart-holder');
            $('.order-summary-top').appendTo('.order-summary-holder');
            $("body").removeClass("tablet-v");
        }
    }
}

// lazyload images
function scrollCheck() {
    $('.lazyload[data-src]:visible').each(function () {
        if ($(this).parent('picture').length) {
            $(this).parent().find('source, img').each(function () {
                if ($(this).attr('data-src')) {
                    $(this).attr('src', $(this).attr('data-src')).removeAttr('data-src');
                }
                if ($(this).attr('data-srcset')) {
                    $(this).attr('srcset', $(this).attr('data-srcset')).removeAttr('data-srcset');
                }
            })
        } else {
            $(this).attr('src', $(this).attr('data-src')).removeAttr('data-src');
            if ($(this).attr('data-srcset')) {
                $(this).attr('srcset', $(this).attr('data-srcset')).removeAttr('data-srcset');
            }
        }
    })
}

$(document).ready(function () {

    $('.hamburger').on('click', function () {
        $(this).add(".nav-main").toggleClass("is-active");
        $('.show-animation').slideToggle();
      });
      $(window).on('resize', function () {
    
        if (window.innerWidth >= 1024) {
          $('.show-animation').css("display", "flex");
        } else {
          $('.show-animation').css("display", "none");
          $('.hamburger').removeClass('is-active');
        }
      });
      $(".show-animation").on("click", "a", function () {
        if (window.innerWidth < 1024) {
          $('.show-animation').css("display", "none");
          $('.hamburger').removeClass('is-active');
        }
      });
      $('.accordion-title').on('click', function () {
        if ($(window).width() < 769) {
          $(this).parent().toggleClass('active');
          $(this).find('.arrowicon').toggleClass('rotate-90');
          $(this).next('.accordion-content').slideToggle();
        }
      });



    scrollCheck();
    resizeCheck();
    $(window).on('scroll', function () {
        scrollCheck();
    });
    $(window).on('resize', function () {
        resizeCheck();
        scrollCheck();
    });

    // before/after function
    $(document).on('input change', '.compare-img input', function () {
        var sliderPos = this.value;
        $(this).parent().find('.before').css('width', sliderPos + '%')
        $(this).parent().find('.trigger').css('left', sliderPos + '%')
    });

    // full thumbnails galery
    new Swiper('.swiper-galery', {
        loop: true,
        navigation: {
            nextEl: '.swiper-galery .swiper-button-next',
            prevEl: '.swiper-galery .swiper-button-prev',
        },
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: 'auto',
        breakpoints: {
            480: {
                centeredSlides: true,
                slidesPerView: 2,
            },
            640: {
                slidesPerView: 3,
                centeredSlides: false,
            },
            768: {
                slidesPerView: 4,
                centeredSlides: false,
            },
            1180: {
                slidesPerView: 5,
                centeredSlides: false,
            },
            1500: {
                slidesPerView: 6,
                centeredSlides: false,
            }
        },
        autoplay: {
            delay: 3000,
        },
    });

//     // hero slider
//    new Swiper('.swiper-hero', {
//         loop: true,
//         effect: 'fade',
//         fadeEffect: {
//             crossFade: true
//         },
//         autoplay: {
//             delay: 5000,
//             disableOnInteraction: false
//         },
//         speed: 1300,
//         spaceBetween: 0,
   
//       });

    // header cart button
    /*$(document).on('click', '.cart-trigger', function () {
        $('body').removeClass('active-menu');
        $('body').toggleClass('active-cart');

        if ($(window).width() > 1023) {
            $('html, body').stop().animate({
                scrollTop: $('.order-cart-holder').offset().top - $('.header').outerHeight()
            }, 500);
        }
        return false;
    });*/

    // mobile menu
    $(document).on('click', '.menu-trigger', function () {
        $('body').removeClass('active-cart');
        $('body').toggleClass('active-menu');
        return false;
    });

    $('.main-menu a').on('click', function () {
        if ($('body').hasClass('active-menu')) {
            $('body').removeClass('active-menu');
        }
    });

    // change widget position on click
    $('.order-cart .btn').on('click', function () {
        let launcher = $('iframe#launcher');
        if (launcher.css('bottom') !== '0px') {
            launcher.css('bottom', '0px');
        }
    });

    // form input labels
    $(document).on('input change keydown', '.form-control', function () {
        if ($(this).val()) {
            $(this).parent().addClass('fill')
        } else {
            $(this).parent().removeClass('fill')
        }
    });

    // mobile footer menu accordion
    $(document).on('click', '.convert-acc .f-title', function () {
        $(this).toggleClass('active').next().toggle('slow');
        return false;
    });

    // faq accordion
    setTimeout(() => {
        $('.faq-head').on('click', function () {
            if ($(this).parent().hasClass('active')) {
                $(this).next('.faq-cc').slideUp();
                $(this).parent().removeClass('active');

            } else {
                $('.faq-item .faq-head').not(this).next('.faq-cc').slideUp();
                $('.faq-item').removeClass('active')
                $(this).parent().addClass('active');
                $(this).next('.faq-cc').slideDown();
            }
        });
    }, 3000);



    
    // smoothsroll
    $(document).on('click', 'a[href^="#"]', function () {
        var $anchor = $(this);
        if ($($anchor.attr('href')).length) {
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - $('.header').outerHeight()
            }, 500);
            return false;
        }
    });
    
});


